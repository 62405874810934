import React, { useState } from 'react';
import './recording-indicator.css';

interface RecordingIndicatorProps {
    isRecording: boolean;
}

const RecordingIndicator: React.FC<RecordingIndicatorProps> = ({ isRecording }) => {

  return (
    <div className="recording-indicator__container">
      <div className={isRecording ? "recording-indicator recording-indicator--on" : "recording-indicator" } >
      </div>
    </div>
  );
};

export default RecordingIndicator;
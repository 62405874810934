import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from '../landing/Landing.tsx';
import RecordingPage from '../recording/RecordingPage.tsx';
import DisplayResult from '../display/DisplayResult.tsx';

function App() {
  return (
    <div className="App">
      <RecordingPage />
    </div>
  );
}

export default App;

import React, { useState, useRef } from 'react';

import './recording-page.css';
import RecordingIndicator from './RecordingIndicator.tsx';
import Instructions from './Instructions.tsx';
import DisplayResult from '../display/DisplayResult.tsx'; // Assuming DisplayResult is properly imported now
import { ContentState } from './types.ts';

const RecordingPage: React.FC = () => {
  // const API_URL = 'http://127.0.0.1:8000';
  const API_URL = 'https://api-braindump.hello-tomo.com';

  const [contentState, setContentState] = useState<ContentState>('initial');
  const [audioUrl, setAudioUrl] = useState<string | null>(null); // holds local URL of audio file
  const [data, setData] = useState<any>(null); // to hold data locally

  const [sessionId, setSessionId] = useState<string | null>(null);
  
  const [error, setError] = useState<String|null>(null);
  const [conversation, setConversation] = useState<string[]>([]);
  const [questions, setQuestions] = useState(["What are you working on?"]);

  const addQuestion = (newQuestion: string) => {
    setQuestions(prevQuestions => [...prevQuestions, newQuestion]);
  };

  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);

  const startRecording = async () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      audioChunksRef.current = [];

      mediaRecorderRef.current.addEventListener('dataavailable', event => {
        audioChunksRef.current.push(event.data);
      });

      mediaRecorderRef.current.start();
      setContentState('recording');
    } else {
      console.error('Audio recording is not supported by your browser.');
    }
  };

  const stopRecording = async () => {
    return new Promise((resolve) => {
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.addEventListener('stop', () => {
          const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/mp3' });
          const audioUrl = URL.createObjectURL(audioBlob);
          setAudioUrl(audioUrl);
          setContentState('recordingIdle');
          resolve();
        });
        mediaRecorderRef.current.stop();
      } else {
        resolve();
      }
    });
  };

  const getNextQuery = async (audioBlob) => {
    const formData = new FormData();
    formData.append('audio', audioBlob);
    console.log(audioBlob.size);
    setContentState('loading');
    formData.append('request', JSON.stringify({ sessionId: sessionId ?? "" }));
    try {
      const response = await fetch(`${API_URL}/braindump`, {
        method: 'POST',
        body: formData,
      });
      if (response.status === 200) {
        const responseData = await response.json();
        console.log('Braindump successful. Response: ', responseData);

        addQuestion(responseData.nextPrompt);
        setSessionId(responseData.sessionId);
        setConversation(responseData.conversation);
        
        setContentState('recordingIdle')
      } else {
        console.error('Failed to send braindump. Status:', response.status);
      }
    } catch (error) {
      console.error('Error sending braindump:', error);
    }
  };

  const digMore = async () => {
    await stopRecording();
    const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/mp3' });
    getNextQuery(audioBlob);
  };

  const createOutline = async () => {
    setContentState('loading');
    const requestObject = {
      sessionId: sessionId ?? "",
      conversation: JSON.stringify(conversation)
    };
    try {
      const response = await fetch(`${API_URL}/outline`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestObject),
      });
      if (response.status === 200) {
        console.log('Outline fetched successfully. Response: ', response);
        response.json().then(responseData => {
          console.log("outlines", responseData.outlines);
          setData(responseData.outlines[0]); // Assuming setData is a function to handle the response data
          setContentState('displayResult');
        }).catch(error => {
          console.error('Error processing JSON:', error);
          setContentState('error');
        });
      } else {
        console.error('Failed to fetch outline. Status:', response.status);
        setContentState('error');
      }
    } catch (error) {
      console.error('Error fetching outline:', error);
      setContentState('error');
    }
  }

  const createDocument = async (audioBlob) => {
    const formData = new FormData();
    formData.append('audio', audioBlob);
    console.log(audioBlob.size);

    try {
      setContentState('loading');
      const response = await fetch(`${API_URL}/document`, {
        method: 'POST',
        body: formData,
      });
      if (response.status === 200) {
        const responseData = await response.json();
        console.log('Audio file successfully uploaded. Response: ', responseData);
        setData(responseData); // Convert string to JSON and store data locally
        setContentState('displayResult');
      } else {
        console.error('Failed to upload audio file. Status:', response.status);
        setError("unable to process");
        setContentState('recordingIdle');
      }
    } catch (error) {
      console.error('Error uploading audio file:', error);
      setContentState('recordingIdle');
    }
  };
  
  return (
      <div className="recording-page">
        <div className="content-section">
          {contentState === 'loading' ? (
            <div className="loading-indicator">Organizing thoughts...</div>
          ) : contentState === 'displayResult' ? (
            <DisplayResult data={data} />
          ) : (
            <RecordingIndicator isRecording={contentState === 'recording'} />
          )}
        </div>
        <div className="instructions-section">
          <Instructions
            questions={questions}
            contentState={contentState}
            startRecording={startRecording}
            digMore={digMore}
            finish={createOutline}
          />
        </div>
      </div>
  );
};

export default RecordingPage;
import React from 'react';
import { ContentState } from './types.ts';

interface InstructionsProps {
  questions: string[];
  contentState: ContentState;
  startRecording: () => void;
  digMore: () => void;
  finish: () => void;
}

const Instructions: React.FC<InstructionsProps> = ({ questions, contentState, startRecording, digMore, finish }) => {
  return (
    <div className="instructions-container">
      <div className="instructions-prompts">
        {questions.map((question, index) => (
          <p key={index} className={index === questions.length - 1 ? "prompt prompt--most_recent" : "prompt"}>{question}</p>
        ))}
      </div>
      <div className="instructions-buttons">
        {contentState === 'recording' ? (
          <button className="button--secondary" onClick={digMore}>Done</button>
        ) : contentState === 'displayResult' ? (
          <button className="button--primary" onClick={startRecording}>Add to this Document</button>
        ) : contentState === 'recordingIdle' ? (
          <> 
            <button className="button--primary" onClick={startRecording}>Start Recording</button>
            <button className="button--primary" onClick={finish}>Finished - see outline</button>
          </>
        ) : contentState === 'loading' ? null : (
          <button className="button--primary" onClick={startRecording}>Start Recording</button>
        )}
      </div>
    </div>
  );
};

export default Instructions;

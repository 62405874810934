import React, { useState } from 'react';
import './display-result.css';

type DataType = {
    title: string;
    subtitle: string;
    body: BodyType;
};

type BodyType =
    Array<{
        headerTitle: string;
        headerType: string;
        content: string[] | BodyType;
    }>

interface DisplayResultProps {
    data: DataType; 
}

const DisplayResult: React.FC<DisplayResultProps> = ({ data }) => {
    const [company, setCompany] = useState('openai');
    const [error, setError] = useState(null);

    if (!data) {
        return <p>No data available</p>;
    }

    console.log("data sent to DisplayResult: " + data);

    const parsedData: DataType = data;

    function toggleCompany(targetCompany: string) {
        setCompany(targetCompany);
    }

    function renderBody(body: BodyType) {
        return body.map((section, index) => (
            renderBodyItem(section, index)
        ));
    }

    function renderBodyItem(section, index) {
        if (section.headerType === "H2") {
            return renderH2Content(section, index);
        } else if (section.headerType === "H3") {
            return renderH3Content(section, index);
        }
    }

    function renderH2Content(section, index) {
        return (
            <div key={index}>
                 <h2>{section.headerTitle}</h2>
                 {Array.isArray(section.content) ? (
                    <ul>
                        {
                            section.content.map((contentItem, idx) => (
                                contentItem.headerTitle != null ? (
                                    renderBodyItem(contentItem, idx)
                                ) : (
                                    <li className="content" key={idx}> {contentItem}</li>
                                )
                            ))
                        }
                    </ul>
                ) : (
                    <p>{section.content}</p>
                )}
            </div>
        );
    }

    function renderH3Content(section, index) {
        return (
            <li>
                <span className="bullet-title">{section.headerTitle}:</span> 
                <span className="content" key={index}> {section.content}</span>
            </li>
        )
    }

    return (
        <div className="display-result-page">
            {/* <div>
                <span className="option-button" onClick={() => toggleCompany('anthropic')}>Anthropic</span>
                <span className="option-button" onClick={() => toggleCompany('openai')}>OpenAI</span>
            </div> */}
            {error ? (
                <div>{error}</div>
            ) : (
                <div className="document">
                    <h1 className="doc-title">{parsedData.title}</h1>
                    <div className="doc-subtitle">{parsedData.subtitle}</div>
                    {renderBody(parsedData.body)}
                </div>
            )}
        </div>
    );
};

export default DisplayResult;

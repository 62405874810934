import React, { useState } from 'react';
import './landing.css';
import { useNavigate } from 'react-router-dom';

const LandingPage: React.FC = () => {
  const [text, setText] = useState('');
  const [submitted, setSubmitted] = useState(false); // State to track button press
  const navigate = useNavigate();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitted(true); 

    // navigate directly to recording page 
    navigate('/record');
    console.log('Submitted text:', text);
  };

  return (
    <div className="landing-page">
      <header className="title">
        <h1 className="name">Braindump</h1>
        <h2>Turn a 10 minute stream-of-consciousness into a beautiful, shareable document</h2>
      </header>
      <main>
        <form onSubmit={handleSubmit}>
          <button type="submit" className={submitted ? 'blue button--pressed' : 'blue'}>Start</button>
        </form>
      </main>
    </div>
  );
};

export default LandingPage;